import Cookies from "js-cookie";
import React, { createContext, useContext, useReducer } from "react";

export const AdminContext = createContext({
  adminInfo: null,
  userRegisterDetails: {},
});
const initialState = {
  adminInfo: Cookies.get("adminInfo")
    ? JSON.parse(Cookies.get("adminInfo"))
    : null,
};

function reducer(state, action) {
  switch (action.type) {
    case "USER_LOGIN":
      return { ...state, adminInfo: action.payload };

    case "USER_LOGOUT":
      return {
        ...state,
        adminInfo: null,
      };

    case "USER_UID":
      return {
        ...state,
        userUID: action.payload,
      };

    case "USER_REGISTER":
      return {
        ...state,
        userRegisterDetails: {
          ...state.userRegisterDetails,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export const AdminProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};

export const useAdminContext = () => useContext(AdminContext);
