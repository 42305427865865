import React, { useState, createContext, useRef } from "react";
import { subMonths } from "date-fns";
// create context
export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const resultsPerPage = 20;

  const [orderInModal, setOrderInModal] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRenderModalOpen, setIsRenderModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [limitData, setLimitData] = useState(20);
  const [isBulkDrawerOpen, setIsBulkDrawerOpen] = useState(false);
  const [lang, setLang] = useState("");
  const [time, setTime] = useState("");
  const [sortedField, setSortedField] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [zone, setZone] = useState("");
  const [status, setStatus] = useState("");
  const [source, setSource] = useState("");
  const [category, setCategory] = useState(null);
  const [modaItemToRender, setModalItemToRender] = useState(null);
  const [drawerIDs, setDrawerIDs] = useState([]);
  const [ordersDateRange, setOrdersDateRange] = useState([
    {
      startDate: subMonths(new Date(), 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //
  const searchRef = useRef("");

  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const closeDrawer = (drawerID) => {
    setDrawerIDs((data) => {
      const all = [...data.filter((id) => id !== drawerID)];
      if (!all.length) {
        setIsDrawerOpen(false);
      }
      return all;
    });
  };
  const openDrawer = (drawerID) => {
    setDrawerIDs((data) => [...new Set(data.concat(drawerID))]);
    drawerID && setIsDrawerOpen(true);
  };
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const closeRenderModal = () => setIsRenderModalOpen(false);
  const toggleRenderModal = () => setIsRenderModalOpen(!isRenderModalOpen);

  const closeBulkDrawer = () => setIsBulkDrawerOpen(false);
  const toggleBulkDrawer = () => setIsBulkDrawerOpen(!isBulkDrawerOpen);

  const handleChangePage = (p) => {
    setCurrentPage(p);
  };

  const handleSubmitForAll = (e) => {
    e.preventDefault();
    if (!searchRef.current.value) return setSearchText(null);
    // setCategory(null);
    setSearchText(searchRef.current.value);
  };

  const onChangeSearchText = (e) => {
    e.persist();
    // setCategory(null);
    setSearchText(e.target.value);
  };

  // const value = useMemo(
  //   () => ({
  //     isSidebarOpen,
  //     toggleSidebar,
  //     closeSidebar,
  //     isDrawerOpen,
  //     toggleDrawer,
  //     closeDrawer,
  //     setIsDrawerOpen,
  //     isModalOpen,
  //     toggleModal,
  //     closeModal,
  //     isUpdate,
  //     setIsUpdate,
  //   }),

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [isSidebarOpen, isDrawerOpen, isModalOpen, isUpdate]
  // );

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
        isDrawerOpen,
        toggleDrawer,
        closeDrawer,
        setIsDrawerOpen,
        isModalOpen,
        toggleModal,
        closeModal,
        isUpdate,
        setIsUpdate,
        closeBulkDrawer,
        isBulkDrawerOpen,
        toggleBulkDrawer,
        lang,
        setLang,
        currentPage,
        setCurrentPage,
        handleChangePage,
        searchText,
        setSearchText,
        category,
        setCategory,
        searchRef,
        handleSubmitForAll,
        status,
        setStatus,
        zone,
        setZone,
        time,
        setTime,
        sortedField,
        setSortedField,
        source,
        setSource,
        resultsPerPage,
        limitData,
        setLimitData,
        onChangeSearchText,
        closeRenderModal,
        toggleRenderModal,
        isRenderModalOpen,
        modaItemToRender,
        setModalItemToRender,
        orderInModal,
        setOrderInModal,
        ordersDateRange,
        drawerIDs,
        setOrdersDateRange,
        openDrawer,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
